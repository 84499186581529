.grid__menu-item {
  all: unset;
  display: block;
  padding: 10px;
  cursor: pointer;
  transition: all 250ms ease-out;
  border-radius: 4px;
}
.grid__menu-item:not(.grid__menu-item--is-active):hover {
  background-color: #f2f5f5;
}
.grid__menu-item--is-active {
  background-color: #e1f0fa;
}
.grid__menu-item--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.grid__menu-item + .grid__menu-item {
  margin-top: 2px;
}

