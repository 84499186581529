/*
  Mixin for applying ellipsis to any element
*/
.ellipsis-menu {
  animation: appear 250ms ease-out;
}
.ellipsis-menu ul {
  background-color: #34495e;
  color: white;
  border-radius: 4px;
  overflow: hidden;
}
.ellipsis-menu ul svg {
  color: #bdc3c7;
  margin-right: 8px;
}
.ellipsis-menu__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 50px;
  padding: 0 12px;
}
.ellipsis-menu__item:not(.ellipsis-menu__item--disabled):hover {
  background-color: #2c3e50;
}
.ellipsis-menu__item:not(.ellipsis-menu__item--disabled):hover svg {
  color: #3498db;
}
.ellipsis-menu__item + .ellipsis-menu__item {
  border-top: 1px solid #2c3e50;
}
.ellipsis-menu__item--disabled {
  cursor: not-allowed;
  opacity: 0.25;
}
.ellipsis-menu__trigger {
  cursor: pointer;
  display: flex;
  gap: 6px;
  padding: 8px;
  border-radius: 5px;
  transition: background-color 250ms ease-out;
  color: #727f80;
}
.ellipsis-menu__trigger:hover {
  background-color: #dde4e6;
  color: black;
}
.ellipsis-menu__arrow:has(+ ul:empty) {
  display: none;
}
.ellipsis-menu__arrow svg path {
  fill: #34495e;
}
.ellipsis-menu__label {
  font-weight: bold;
}
.ellipsis-menu--closed {
  display: none;
}

